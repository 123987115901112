import {get, post, put, del, getExel,putUserId,delUserId,postUserId} from './base_y'
import qs from 'qs'
//玉松本地接口测试-冯
//--------------------------------------------医学会议-点播回放-------------------------------
//医学会议-点播回放
export function get_demand_playback_list(id,data){
    return get("/v3/vod/contents?conventionId="+id,data)
}
//获取单个
export function get_demand_playback_one_list(id){
    return get("/v3/vod/content/"+id)
}
export function demand_playback_list_upDown(id,moveUpDown){
    return post("/v3/vod/moveUpDown/"+id +'?moveUpDown=' + moveUpDown)
}
//获取签名
export function get_signature(){
    return get("/v3/getUgcUploadSign")
}
//获取专家的姓名和id
export function get_experts_id(name,pageNum,pageSize){
    return get("/v3/experts",{name,pageNum,pageSize})
}
//获取视频url
export function get_videoLink(videoId){
    return get("/v3/videoLink",{videoId})
}
//上传视频
export function post_video(fileId,type,data){
    return post("/v3/vod/video/upload"+'?fileId='+fileId+'&type='+type,data)
}
//上传视频
export function put_video_two(id,type,data){
    return put("/v3/vod/content/"+id+'?type='+type,data)
}
//上传视频
export function put_video(id,type,fileId,data){
    return put("/v3/vod/content/"+id+'?type='+type+'&fileId='+fileId,data)
}
//删除
export function del_videoList(id){
    return del("/v3/vod/content/"+id)
}
//--------------------------------------------点播视频管理-------------------------
//点播管理
export function get_column_vods(data){
    return get('/v3/column/vods',data)
}
//新增
export function post_video_upload(type,data){
    return post("/v3/vod/video/upload"+'?type='+type,data)
}
//上移下移
export function column_vods_moveUpDown(id,moveUpDown,code){
    return post('/v3/vod/moveUpDown/'+id+'?moveUpDown='+moveUpDown+'&code='+code)
}
//通过关键字获取视频
export function get_keyWord_vods(keyword){
    return get('/v3/vod/videos',{keyword})
}
//选择视频
export function post_choose_vods(vodId,videoId,data){
    return post('/v3/Max/item/video/'+vodId+'/'+videoId,data)
}
//获取要选择的医学会议
export function get_case_room_vods(pageNum,pageSzie,title){
    return get('/v3/vod/conventions',{pageNum,pageSzie,title})
}
//导入视频
export function post_lead_vods(vodId,conventionId){
    return post('/v3/Max/item/'+conventionId+'?vodId='+vodId)
}
//关联会议---导入视频
export function post_lead_vods_two(vodId,conventionId){
    return post('/v3/Max/item/'+conventionId+'?vodId='+vodId)
}
//获取会议视频的数量
export function get_vods_num(conventionId){
    return get('/v3/convention/vod/'+conventionId)
}
//专题视频的上移下移
export function post_lead_vods_moveUpDown(id,vodId,moveUpDown){
    return post('/v3/vod/max/moveUpDown/'+id+'/'+vodId+'?moveUpDown='+moveUpDown)
}
//移除视频---专题
export function del_list_zhuan_vods(id){
    return del('/v3/vod/max/'+id)
}
//获取专题数据
export function get_vods_topic(vodId,title){
    return get('/v3/vod/topic',{vodId,title})
}
//获取片头片尾
export function get_startEnd(type,pageNum,pageSize,title){
    return get('/v3/shorts',{type,pageNum,pageSize,title})
}
//获取专题列表数据
export function get_specials_list(data){
    return get('/v3/topics',data)
}
//修改视频库管理专题
export function put_video_topic(maxId,id){
    return put('/v3/yun/video/topic/'+maxId+'?id='+id)
}
//----------------------------片头片尾管理--------------------------------------------------------
export function get_opening_credits(data){
    return get('/v3/yun/shorts',data)
}
//修改片头片尾
export function put_startEnd(id,data){
    return put('/v3/yun/short/'+id,data)
}
//---------------------------文章管理-----------------------------------------------------
export function get_active_list(expert,title,code,pageNo,pageNum){
    return get('/v3/yun/platform/article?pageNo='+pageNo+'&pageNum='+pageNum+'&title='+title+'&code='+code+'&expert='+expert)
}
export function del_active_list(id) {
    return delUserId('/v3/yun/platform/article/'+id)
}
//move: up:上移，down：下移
export function move_active_list(id,move) {
    return putUserId('/v3/yun/platform/article/'+id+'/'+move)
}
export function post_active_list(data) {
    return postUserId('/v3/yun/platform/article',data)
}
export function put_active_list(data) {
    return putUserId('/v3/yun/platform/article',data)
}
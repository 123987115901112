<template>
  <div style="margin: -15px -15px;background-color: #fff;">
    <a-spin :spinning="spinning">
    <div class="video-top">
      <div>
        <span>选择年份：</span>
        <a-date-picker
            v-model="year"
            mode="year"
            v-decorator="['year']"
            placeholder="请输入年份"
            format="YYYY"
            class="input"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
      </div>
<!--      <div>-->
<!--        <span>专家姓名：</span>-->
<!--        <a-input placeholder="请输入专家姓名" class="input" v-model="expertsName"/>-->
<!--      </div>-->
      <div>
        <span>视频标题：</span>
        <a-input placeholder="请输入视频标题" class="input" v-model="videoTitle"/>
      </div>
      <div>
        <a-button @click="lookBtn" type="primary">查询</a-button>
        <a-button @click="restBtn" style="margin-left: 15px">重置</a-button>
      </div>
    </div>
    <div class="video-content">
      <div class="video-content-l">
        <!-- 菜单选择-->
        <div>
          <a-menu
              style="width: 100%"
              v-model="selectId"
              mode="inline"
          >
            <a-menu-item
                v-for="(item,index) in leftTemplete"
                @click="selectStatus(item)"
                :key="item.code">
              {{item.title}}
            </a-menu-item>
          </a-menu>
        </div>
      </div>
      <div class="video-content-r">
        <div class="r-btn">
          <a-button @click="newProject" type="primary">新增专题</a-button>
<!--          <a-button style="margin-left: 15px">新增单视频</a-button>-->
        </div>
        <div>
          <a-table
              :pagination="false"
              :columns="columns"
              :data-source="data"
              :row-key="record => record.id">
            <span slot="sort" slot-scope="text, record">
              <a @click="upDownBtn(record,'up')">上移</a>
              <a @click="upDownBtn(record,'down')" style="margin-left: 8px">下移</a>
            </span>
            <span slot="name" slot-scope="text, record">
              <a-button v-if="record.type === 'VOD_SET_TOPIC'" size="small" type="primary">专题</a-button>
              <a-button v-else style="color: #4a9bfa;background-color: #dcf0f6;border: 1px solid #4a9bfa" size="small">视频</a-button>

              <a v-if="record.type === 'VOD_SET_TOPIC'" style="margin-left: 10px" @click="titleBtn(record.id)">{{ text }}</a>
              <a v-else style="margin-left: 10px" @click="openUrl(record.id)">{{ text }}</a>
            </span>
            <span slot="action" slot-scope="text, record">
              <a>推广</a>
              <a style="margin-left: 8px">动态</a>
              <a-dropdown style="margin-left: 8px">
                <a-menu slot="overlay" >
                  <a-menu-item v-if="record.type === 'VOD_SET_TOPIC'" @click="table_restBtn(record.id)" key="1">
                     修改
                  </a-menu-item>
                  <a-menu-item  @click="table_delBtn(record.id)" key="2">
                     删除
                  </a-menu-item>
                </a-menu>
                <!--                <a-button type="primary" style="margin-left: 8px"> 操作 <a-icon type="down" /> </a-button>-->
                <a class="ant-dropdown-link" > 操作 <a-icon type="down" /></a>
              </a-dropdown>
            </span>
          </a-table>
          <div style="width: 100%;margin-top: 15px">
            <div style="float: right;margin-right: 20px">
              <a-pagination
                  show-quick-jumper
                  :default-current="pageNum"
                  :total="total"
                  @change="onChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
<!--------------------------------------------------对话框------------------------------------------->
    <a-drawer
        title="新增/编辑专题"
        :width="700"
        :visible="visible_upVideo"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_video"
    >
      <a-form-model
          ref="content_ruleForm"
          :model="videoForm"
          :rules="content_video_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol">
        <a-form-model-item prop="surface_plot" label="列表封面图 ">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_surface_plot_image"
                  ref="content_surface_plot_original_image" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="videoForm.surface_plot"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="videoForm.surface_plot"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注：尺寸 1920*520，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item prop="coverUrl" label="详情封面图 ">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_surface_plot_original"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_surface_plot_original_video"
                  ref="content_surface_plot_video_image" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="videoForm.coverUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="videoForm.coverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注：尺寸 640*360，大小不超过500kb</div>
        </a-form-model-item>
        <a-form-model-item  prop="title" label="标题 ">
          <a-input
              v-model="videoForm.title"
              style="width: 300px"
              placeholder="请输入标题"
          />
        </a-form-model-item>
        <a-form-model-item label="栏目">
          <a-select
              v-model="videoForm.specialist"
              show-search
              placeholder="请选择栏目"
              option-filter-prop="children"
              style="width: 300px"
              @change="video_demand_handleChange"
          >
            <a-select-option
                v-for="(select_spe,index) in leftTemplete"
                :key="index"
                :value="select_spe.code">
              {{select_spe.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="include" label="介绍 ">
          <div ref="editor"></div>
        </a-form-model-item>
        <a-form-model-item label="关键字 ">
          <a-input
              v-model="videoForm.keyName"
              style="width: 300px"
              placeholder="请输入关键字"
          />
        </a-form-model-item>
        <a-form-model-item label="关联会议">
          <a-tooltip placement="top">
            <template slot="title">
              <span>prompt text</span>
            </template>
            <a-icon style="margin-right: 10px" type="info-circle" />
          </a-tooltip>
          <a-select
              v-model="videoForm.AssociationMeet"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 300px"
              @popupScroll="popupScroll_live"
              @search="select_live"
          >
            <a-select-option
                v-for="(selectM,index) in CaseLiveList"
                :key="index"
                :value="selectM.id">
              {{selectM.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_video">
          取消
        </a-button>
        <a-button
            style="margin-left: 10px"
            type="primary" @click="onsubmit_video">
          确定
        </a-button>
      </div>
    </a-drawer>
    <a-drawer
        title="查看专题视频"
        :width="1200"
        :visible="visible_Check_video"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_Check_video"
    >
      <div style="width: 98%;margin: auto;">
        <div class="addVideo">添加视频</div>
        <div style="display: flex;">
          <div style="width: 40%">
            <a-select
                size="large"
                v-model="selectHosModel"
                show-search
                placeholder="选择医学会议"
                option-filter-prop="children"
                style="width:80%"
                @popupScroll="popupScroll_model"
                @search="select_model"
                @change="onSearch_model_hos"
            >
              <a-select-option
                  v-for="(selectHos,index) in selectHosList"
                  :key="index"
                  :value="selectHos.conventionId">
                {{selectHos.title}}
              </a-select-option>
            </a-select>
          </div>
          <div style="width: 60%">
            <a-input-search
                v-model="search_model"
                style="width: 80%"
                placeholder="请输入标题关键字搜索视频库"
                enter-button="搜索"
                size="large"
                @search="onSearch_model"
            />
          </div>
        </div>
        <div style="display: flex;margin-top: 10px">
          <div style="width: 40%">
            <a-alert
                style="width: 80%;height: 130px"
                message="搜索结果"
                type="info"
                show-icon
            >
              <div style="font-size: 14px;padding-top: 10px" slot="description">
                <div style="color: #b0afaf">共搜索到{{videoNum}}个视频</div>
                <div style="padding-top: 10px"><a @click="confirmImport">确认导入</a></div>
              </div>
            </a-alert>
          </div>
          <div style="width: 60%">
            <a-alert
                style="width: 90%;height: 130px"
                message="搜索结果"
                type="info"
                show-icon
            >
              <div slot="description">
                  <div v-for="(keyVideo,index) in keyWordVod" :key="index">
                    <span>{{index+1}}、{{keyVideo.title}}</span>
                    <a @click="confirmChoice(keyVideo.id,keyVideo.cover_img)" style="margin-left: 10px">确认选择</a> ｜ <a @click="openVideo(keyVideo.media_url)">查看视频</a>
                  </div>
              </div>
            </a-alert>
          </div>
        </div>
        <div class="specialCatalog"><span>专题目录</span></div>
        <div style="display: flex">
          <span style="margin-top: 5px">标题：</span>
          <a-input v-model="titleVal" placeholder="请输入" style="width: 300px"/>
          <a-button @click="getZhuanTableList" style="margin-left: 15px" type="primary">查询</a-button>
        </div>
        <div class="child-content">
          <a-table :columns="columns_model" :data-source="data_model">
            <span slot="sort" slot-scope="text, record">
              <a @click="upDownBtnZhuan(record.id,'up')">上移</a>
              <a @click="upDownBtnZhuan(record.id,'down')" style="margin-left: 8px">下移</a>
            </span>
            <span slot="hospital" slot-scope="text, record">
              {{record.name}} - {{record.hospital}}
            </span>
            <span slot="action" slot-scope="text, record">
              <a-popconfirm
                  title="是否确认删除?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirm_del(record.id)"
              ><a href="#">删除</a></a-popconfirm>
            </span>
          </a-table>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_Check_video">
          取消
        </a-button>
      </div>
    </a-drawer>
    </a-spin>
  </div>
</template>
<script>
import moment from "moment";
import {update} from "../../../utils/update";
import E from "wangeditor";
import {
  column_vods_moveUpDown, del_list_zhuan_vods,
  del_videoList,
  get_case_room_vods,
  get_column_vods,
  get_demand_playback_one_list,
  get_keyWord_vods,
  get_vods_num,
  get_vods_topic,
  post_choose_vods,
  post_lead_vods,
  post_lead_vods_moveUpDown, post_lead_vods_two,
  post_video,
  post_video_upload, put_video, put_video_two
} from "@/service/MedicalConference_y";
import {CaseLive} from "@/service/MedicalConference";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width:'50%',
    scopedSlots: { customRender: 'name' },
    ellipsis: true,
  },{
    title: '时间',
    dataIndex: 'created_time',
    key: 'created_time',
    ellipsis: true,
  },{
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    width:'10%',
    scopedSlots: { customRender: 'sort' },
  },{
    title: '操作',
    key: 'action',
    width:'15%',
    scopedSlots: { customRender: 'action' },
  },
];
const columns_model = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width:'30%',
    ellipsis:true,
  },{
    title: '作者',
    dataIndex: 'hospital',
    key: 'hospital',
    scopedSlots: { customRender: 'hospital' },
    width:'30%',
  },{
    title: '创建时间',
    dataIndex: 'created_time',
    key: 'created_time',
    width:'15%',
  },{
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    width:'10%',
    scopedSlots: { customRender: 'sort' },
  },{
    title: '操作',
    key: 'action',
    width:'10%',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  name:'videoOnDemand',
  data(){
    return{
      pageNum:1,
      tableListId:'',
      total:0,
      spinning:false,
      data:[],
      titleVal:'',
      data_model:[],
      columns,
      columns_model,
      yearShowOne:false,
      year:'',
      // expertsName:'',
      videoTitle:'',
      selectId:[],
      leftTemplete:[],
      visible_upVideo:false,
      videoForm:{
        surface_plot:'',
        coverUrl:'',
        title:'',
        specialist:undefined,
        include:'',
        keyName:'',
        AssociationMeet:undefined,
      },
      content_video_rules:{
        surface_plot: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '详情封面图', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        include: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
      },
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      editor:null,
      editor_menus:[
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'fullscreen' // 全屏
      ],
      visible_Check_video:false,
      CaseLiveList:[],
      CaseLiveList_page_no:1,
      CaseLiveList_title:'',
      oneList:{},
      selectHosModel:undefined,
      videoNum:0,
      vodId:'',
      selectHosList:[],
      selectHosList_page_no:1,
      selectHosList_title:'',
      search_model:'',
      keyWordVod:[],
    }
  },
  created() {
    this.getList()
    this.$store.dispatch('setManageHeaderTitle','点播视频管理')
  },
  methods:{
    //获取列表
    async getList() {
      this.spinning = true
      let data = {
        title:this.videoTitle,
        // name:this.expertsName,
        year:this.year,
        pageNum:this.pageNum,
        pageSize:10,
        type:'vod',
      }
      const response = await get_column_vods(data)
      if(response.code === 0){
        this.leftTemplete = response.data
        if(!this.selectId[0]){
          this.selectId[0] = response.data[0].code
        }
        //解决分页问题
        for(let i in  response.data){
          if(this.selectId[0] === response.data[i].code){
            this.data = response.data[i].vodContents
            this.total = response.data[i].count
          }
        }
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    onChange(pageNumber){
      this.pageNum = pageNumber
      this.getList()
    },
    upDownBtn(list, moveUpDown) {
      this.moveUpDown_list(list.id,moveUpDown,this.selectId[0])
    },
    async moveUpDown_list(id, moveUpDown, code) {
      const response = await column_vods_moveUpDown(id, moveUpDown, code)
      if (response.code === 0) {
        this.$message.success("操作成功！")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async CaseLive_list(page_no,title) {
      const response = await CaseLive(page_no,10,'',title)
      if (response.code === 0) {
        // this.CaseLiveList = response.data.rows
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.CaseLiveList.push({
            id: list[i].id,
            title: list[i].title,
          })
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    popupScroll_live(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.CaseLiveList_page_no++
        this.CaseLive_list(this.CaseLiveList_page_no,this.CaseLiveList_title)
      }
    },
    //鼠标搜索
    select_live(value){
      //首先清空数组，否则会数据重复
      this.CaseLiveList = []
      this.CaseLiveList_title = value
      this.CaseLive_list(1,this.CaseLiveList_title)
    },

    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
      console.log(this.year)
    },
    lookBtn(){
      this.getList()
    },
    restBtn(){
      this.year = '';
      // this.expertsName = '';
      this.videoTitle = '';
      this.getList()
    },
    selectStatus(list){
      this.selectId[0] = list.code;
      this.pageNum = 1;
      this.getList()
      this.total = list.count;

    },
    async table_restBtn(id) {
      this.tableListId = id
      //获取医学会议列表
      await this.CaseLive_list()
      //获取单个视频详情
      this.getOneList(id)
      this.visible_upVideo = true
      this.$nextTick(() => {
        if (this.editor === null) {
          this.create_editor_config()
        } else {
          this.editor.destroy()
          this.create_editor_config()
        }
      })
    },

    async getOneList(id) {
      const response = await get_demand_playback_one_list(id)
      if (response.code === 0) {
        this.oneList = response.data
        let form = this.videoForm
        let data = response.data

        form.surface_plot = data.cover[0]
        form.coverUrl = data.cover[1]
        form.title = data.title
        form.specialist = data.code
        //介绍
        if(data.intro){
          this.editor.txt.html(data.intro)
        }
        form.keyName = data.keyWord
        form.AssociationMeet = data.convent_id
      } else {
        this.$message.warning(response.message)
      }
    },
    async table_delBtn(id) {
      const response = await del_videoList(id)
      if (response.code === 0) {
        await this.getList()
        this.$message.success('删除成功~')
      } else {
        this.$message.warning(response.message)
      }
    },
    close_video(){
      this.tableListId = ''
      this.CaseLiveList = []
      this.CaseLiveList_page_no = 1
      this.CaseLiveList_title = ''
      this.$refs.content_ruleForm.resetFields();
      this.videoForm.specialist = []
      this.videoForm.keyName = ''
      this.videoForm.AssociationMeet = undefined
      this.visible_upVideo = false
    },
    onsubmit_video(){
      this.videoForm.include = this.editor.txt.html()
      this.$refs.content_ruleForm.validate(async valid => {
        if (valid) {
          let form = this.videoForm
        //  判断新增还是修改
          if(this.tableListId){
          //  修改
            let data = {
              cover:[form.surface_plot,form.coverUrl],//封面
              // coverImg:form.coverUrl,//视频封面
              title:form.title,//标题
              intro:form.include,//介绍
              column_code:form.specialist,//栏目代码
              // conventionId:form.AssociationMeet,//会议id
              keyWord:form.keyName,
            }
            await this.restDianVode(this.tableListId, data)

          }else {
          //  新增
            let data = {
              cover:[form.surface_plot,form.coverUrl],//封面
              // coverImg:form.coverUrl,
              title:form.title,//标题
              intro:form.include,//介绍
              column_code:form.specialist,//栏目代码
              // conventionId:form.AssociationMeet,//会议id
              keyWord:form.keyName
            }
            await this.addDianVode(data)
          }
          this.visible_upVideo = false
          this.$refs.content_ruleForm.resetFields();
          this.videoForm.specialist = []
          this.videoForm.keyName = ''
          this.videoForm.AssociationMeet = undefined

          this.CaseLiveList = []
          this.CaseLiveList_page_no = 1
          this.CaseLiveList_title = ''
        } else {
          this.$message.warning("表单有空~")
          return false;
        }
      })
    },
    //新增
    async addDianVode(data) {
      const response = await post_video_upload('Topic',data)
      if(response.code === 0){
        this.$message.success('新增成功~')
        //获取id,调取接口  vodId - 新增专题的id,conventionId -- 会议id
        await this.AssociationMeetChange(response.data,this.videoForm.AssociationMeet)
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    //关联会议
    async AssociationMeetChange(vodId,conventionId) {
      const response = await post_lead_vods_two(vodId,conventionId)
      if (response.code === 0) {
        this.$message.success('关联成功~')
      } else {
        this.$message.warning(response.message)
      }
    },
    //修改
    async restDianVode(id,data) {
      const response = await put_video_two(id,'Topic',data)
      if(response.code === 0){
        this.$message.success('修改成功~')
        await this.getList()
        this.tableListId = ''
      }else {
        this.$message.warning(response.message)
      }
    },
    async content_surface_plot_image() {
      let inputDOM = this.$refs.content_surface_plot_original_image.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.videoForm.surface_plot = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_surface_plot').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    async content_surface_plot_original_video() {
      let inputDOM = this.$refs.content_surface_plot_video_image.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.videoForm.coverUrl = image.data.url
          this.$message.success("上传成功")
          document.getElementById('up_img_surface_plot_original').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    video_demand_handleChange(){

    },
    //富文本编辑器
    create_editor_config(){
      this.$nextTick(() => {
        //等待DOM加载完毕
        const editor =  new E(this.$refs.editor)
        this.editor = editor
        //去除图片视频上传
        editor.config.editor_menus = [
          'image',
          'video'
        ]
        // 设置编辑区域高度为 500px
        editor.config.height =200
        //创建编辑器
        editor.create()
      })
    },
    newProject(){
      this.CaseLive_list()
      this.visible_upVideo = true
      this.$nextTick(() => {
        if (this.editor === null) {
          this.create_editor_config()
        } else {
          this.editor.destroy()
          this.create_editor_config()
        }
      })
    },
    titleBtn(id){
      this.vodId = id
      this.visible_Check_video = true
      //获取医院列表
      this.getHosList()
      //获取表格列表数据
      this.getZhuanTableList()
    },
    async getZhuanTableList() {
      const response = await get_vods_topic(this.vodId,this.titleVal)
      if (response.code === 0) {
        this.data_model = response.data.maxs
      } else {
        this.$message.warning(response.message)
      }
    },
    async getHosList(pageNum,title) {
      const response = await get_case_room_vods(pageNum,10,title)
      if (response.code === 0) {
        // this.selectHosList = response.data
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.selectHosList.push({
            conventionId: list[i].conventionId,
            title: list[i].title,
          })
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    popupScroll_model(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.selectHosList_page_no++
        this.getHosList(this.selectHosList_page_no,this.selectHosList_title)
      }
    },
    //鼠标搜索
    select_model(value){
      //首先清空数组，否则会数据重复
      this.selectHosList = []
      this.selectHosList_title = value
      this.getHosList(1,this.CaseLiveList_title)
    },
    close_Check_video(){
      this.selectHosModel = undefined
      this.search_model = ''
      this.selectHosList_page_no =  1
      this.selectHosList_title = ''
      this.videoNum = 0
      this.keyWordVod = []
      this.selectHosList = []
      this.visible_Check_video = false
    },
    onsubmit_Check_video(){
      this.selectHosList = []
      this.selectHosList_page_no =  1
      this.selectHosList_title = ''
      this.visible_Check_video = false
    },
    async onSearch_model(value) {
      this.keyWordVod = []
      const response = await get_keyWord_vods(value)
      if (response.code === 0) {
        let len = response.data.length
        if(len > 3){
          for (let i=0;i<3;i++){
            this.keyWordVod.push(response.data[i])
          }
        }else {
          for (let i=0;i<len;i++){
            this.keyWordVod.push(response.data[i])
          }
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async onSearch_model_hos() {
      let conventionId = this.selectHosModel
      const response = await get_vods_num(conventionId)
      if (response.code === 0) {
        this.videoNum = response.data
      } else {
        this.$message.warning(response.message)
      }
    },
    openVideo(url){
      window.open(url)
    },
    //确认导入
    async confirmImport() {
      if (this.selectHosModel) {
        const response = await post_lead_vods(this.vodId, this.selectHosModel)
        if (response.code === 0) {
          this.$message.success(response.message)
          // 刷新列表
          await this.getZhuanTableList()
        } else {
          this.$message.warning(response.message)
        }
      } else {
        this.$message.warning('请先选择医学会议在进项导入~')
      }
    },
    upDownBtnZhuan(id,moveUpDown){
      this.postVodMoveUpDown(id,moveUpDown)
    },
    //点播专题对话框公共的上移下移
    async postVodMoveUpDown(id,moveUpDown) {
      const response = await post_lead_vods_moveUpDown(id,this.vodId,moveUpDown)
      if (response.code === 0) {
        this.$message.success('操作成功~')
        // 刷新列表
        await this.getZhuanTableList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async confirmChoice(id,cover_img) {
      let data = {
        cover: [cover_img]//封面
      }
      const response = await post_choose_vods(this.vodId,id,data)
      if (response.code === 0) {
        this.$message.success('添加成功~')
        // 刷新列表
        await this.getZhuanTableList()
      }else if (response.code === -1){
        this.$message.warning('该视频已被添加~')
      }
      else {
        this.$message.warning(response.message)
      }
    },
    async confirm_del(id) {
      const response = await del_list_zhuan_vods(id)
      if (response.code === 0) {
        this.$message.success('删除成功~')
        // 刷新列表
        await this.getZhuanTableList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async openUrl(id) {
      const response = await get_demand_playback_one_list(id)
      if (response.code === 0) {
        window.open(response.data.media_url)
      }else {
        this.$message.warning(response.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-top{
    width: 100%;
    height: 80px;
    display: flex;
    padding-top: 2%;
    justify-content: space-around;
    .input{
      width: 65%;
      margin-left: 10px;
    }
  }
  .video-content{
    width: 100%;
    min-height: 600px;
    border-top: 13px solid #f0f2f5;
    display: flex;
    .video-content-l{
      width: 13%;
      height: auto;
    }
    .video-content-r{
      width: 87%;
      height: auto;
      border-left: 13px solid #f0f2f5;
      .r-btn{
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    background-image: url("../../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_LiveBroadcast{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  .addVideo{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
  .specialCatalog{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    border-top: 1px solid #e7e7e7;
  }
  .child-content{
    margin-top: 15px;
  }
</style>